const round = (value: number, toFixed = 2) => {
  if (!value) return 1;
  if (typeof value !== 'number') return parseFloat(value);
  return parseFloat(value.toFixed(toFixed));
};

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const number = {
  round,
  random,
};

export default number;
