import { Theme } from '@mui/material/styles';

export default function Button({ palette }: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: palette.primary.main,
          color: palette.common.white,
        },
        text: {
          color: palette.common.white,
          '&:hover, &.active': {
            color: palette.common.white,
            opacity: 1,
            backgroundColor: 'rgba(256,256,256, .2)',
          },
        },
        sizeLarge: {
          height: 56,
        },
      },
    },
  };
}
