import { Theme } from '@mui/material/styles';

export default function Fab({ palette }: Theme) {
  return {
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          color: palette.common.white,
          background: palette.common.black,
          '&:hover': {
            background: palette.treedis.blue.main,
          },
        },
        extended: {
          borderRadius: '5px',
        },
      },
    },
  };
}
