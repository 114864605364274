const hexToRgbA = (hex, opacity) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`;
  }
  return 'rgba(255,255,255,0.25)';
};

const isHex = (hex: string) => /#?([A-F]|[a-f]|\d){3,6}$/.test(hex);

const color = {
  hexToRgbA,
  isHex,
};

export default color;
