export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },

      styleOverrides: {
        root: {
          background: `rgba(0, 0, 0, 0.7)`,
          // Backdrop filter has some performance issues and is applied with delay. Commented out for now.
          // backdropFilter: 'blur(15px)',
          // boxShadow: 'inset 0 0 100px 85px #212121',
        },
      },
    },
  };
}
