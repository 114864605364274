import { Theme } from '@mui/material/styles';

export default function Tabs({ palette }: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: palette.common.white,
        },
      },
    },
  };
}
