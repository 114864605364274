export const CONCURRENT_CALLS_NUMBER = 4;

type TExecutingPoolItem = () => any;

export class ExecutingPool {
  private pool: Array<TExecutingPoolItem> = [];

  private readonly size: number;

  constructor(size: number) {
    this.size = size;
  }

  async add(value: TExecutingPoolItem) {
    if (!value) return;

    if (this.pool.length < this.size) {
      this.pool.push(value);
      await value();
      const index = this.pool.length - 1;
      this.pool.splice(index, 1);
    }
  }
}
