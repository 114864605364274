/* eslint-disable @typescript-eslint/no-explicit-any,no-useless-concat */
// TODO: fix type any
import JSZip from 'jszip';
import { file, link } from './index';

export default class AnalyticsCSVGenerator {
  result: string;

  eachResult: { name: string; data: any }[] = [];

  tourName: string;

  startDate: string;

  endDate: string;

  constructor(tourName: string, startDate: string, endDate: string) {
    this.tourName = tourName;
    this.startDate = startDate;
    this.endDate = endDate;

    this.generateCSV = this.generateCSV.bind(this);
    this.appendData = this.appendData.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
  }

  generateCSV = (data: any, csvDataStorage: any, conversionValues: any) => {
    if (!data || !csvDataStorage || !conversionValues) return;

    const viewsCsv = `VIEWS:, ${data.totals?.views || 0}`;
    this.appendData(viewsCsv, 'Views', 'Views');

    const timeCsv = `TIME (MIN):, ${(data.totals?.time || 0) / 60}`;
    this.appendData(timeCsv, 'Time (min)', 'Time (min)');

    const usersCsv = `USERS:, ${data.totals?.users || 0}`;
    this.appendData(usersCsv, 'Users', 'Users');

    const usersVsViewsData =
      `DAYS:, ${csvDataStorage.usersVsViews.days.join(', ')}\n` +
      `USERS:, ${csvDataStorage.usersVsViews.users.join(', ')}\n` +
      `VIEWS:, ${csvDataStorage.usersVsViews.views.join(', ')}`;
    this.appendData(usersVsViewsData, 'Users Vs. Views', 'Users vs. Views Chart (days users views)');

    const averageTimeData = `DAYS:, ${csvDataStorage.averageTime.days.join(', ')}\n` + `TIME:, ${csvDataStorage.averageTime.time.join(', ')}`;
    this.appendData(averageTimeData, 'Average time', 'Average time spent per days');

    const usersVsViewsByHourForData =
      `DAYS:, ${csvDataStorage.usersVsViewsByHourFor.days.join(', ')}\n` +
      `USERS:, ${csvDataStorage.usersVsViewsByHourFor.users.join(', ')}\n` +
      `VIEWS:, ${csvDataStorage.usersVsViewsByHourFor.views.join(', ')}`;
    this.appendData(usersVsViewsByHourForData, 'Users vs Views by hour', 'Users vs Views by hour (days users views)');

    const interactivityData =
      `TAGS CLICKS:, ${csvDataStorage.interactivity.data[0]}\n` +
      `MENU CLICKS:, ${csvDataStorage.interactivity.data[1]}\n` +
      `MAP CLICKS:, ${csvDataStorage.interactivity.data[2]}\n` +
      `INTRO BUTTON CLICKS:, ${csvDataStorage.interactivity.data[3]}`;
    this.appendData(interactivityData, 'Interactivity', 'Interactivity (clicks)');

    const deviceChartByUsersData =
      `DESKTOP:, ${csvDataStorage.deviceChartByUsers.data[0]}\n` +
      `MOBILE:, ${csvDataStorage.deviceChartByUsers.data[1]}\n` +
      `TABLET:, ${csvDataStorage.deviceChartByUsers.data[2]}`;
    this.appendData(deviceChartByUsersData, 'Device chart by users data', 'Device chart by users data');

    const deviceChartByViewsData =
      `DESKTOP:, ${csvDataStorage.deviceChartByViews.data[0]}\n` +
      `MOBILE:, ${csvDataStorage.deviceChartByViews.data[1]}\n` +
      `TABLET:, ${csvDataStorage.deviceChartByViews.data[2]}`;
    this.appendData(deviceChartByViewsData, 'Device chart by views', 'Device chart by views');

    // Conversion chart data

    const contactFormConversionValues =
      `OPEN:, ${conversionValues.conv.convOpen}\n` + `SUBMIT:, ${conversionValues.conv.convSubmit}\n` + `CONVERSION RATE (%):, ${conversionValues.conv.conversion}`;
    this.appendData(contactFormConversionValues, 'Conversion Rate - Contact Form', 'Leads from Contact Form - Conversion Rate');

    const sweepConversionValues =
      `OPEN:, ${conversionValues.sweep.sweepOpen}\n` + `SUBMIT:, ${conversionValues.sweep.sweepSubmit}\n` + `CONVERSION RATE (%):, ${conversionValues.sweepConversion}`;
    this.appendData(sweepConversionValues, 'Conversion Rate - Sweep', 'Leads from Sweep - Conversion Rate');

    const imageConversionValues =
      `OPEN:, ${conversionValues.image.imageOpen}\n` + `SUBMIT:, ${conversionValues.image.imageSubmit}\n` + `CONVERSION RATE (%):, ${conversionValues.image.imageConversion}`;
    this.appendData(imageConversionValues, 'Conversion Rate - Image', 'Leads from Image Form - Conversion Rate');

    const entryConversionValues =
      `OPEN:, ${conversionValues.entry.entryOpen}\n` + `SUBMIT:, ${conversionValues.entry.entrySubmit}\n` + `CONVERSION RATE (%):, ${conversionValues.entry.entryConversion}`;
    this.appendData(entryConversionValues, 'Conversion Rate - Entry', 'Leads from Tour Entry - Conversion Rate');

    const tagClicksData = `TAGS:, ${csvDataStorage.tagClicks.labels.join(', ')}\n` + `CLICKS:, ${csvDataStorage.tagClicks.data.join(', ')}`;
    this.appendData(tagClicksData, 'Tag clicks', 'Tag clicks');

    const menuClicksData = `MENU ITEM:, ${csvDataStorage.menuClicks.labels.join(', ')}\n` + `CLICKS:, ${csvDataStorage.menuClicks.data.join(', ')}`;
    this.appendData(menuClicksData, 'Menu clicks', 'Menu clicks');

    const heatmapData =
      `TAG NAME:, ${csvDataStorage.heatmap.tags.map((e) => e.name).join(', ')}\n` +
      `TAG HEAT:, ${csvDataStorage.heatmap.tags.map((e) => e.heat).join(', ')}\n` +
      `SWEEP NAME:, ${csvDataStorage.heatmap.sweeps.map((e) => e.name).join(', ')}\n` +
      `SWEEP HEAT:, ${csvDataStorage.heatmap.sweeps.map((e) => e.heat).join(', ')}`;
    this.appendData(heatmapData, 'Heatmap', 'Heatmap (tags and sweeps)');

    const sourcesViewsData = `LABEL:, ${csvDataStorage.sourcesViews.labels.join(', ')}\n` + `VIEWS:, ${csvDataStorage.sourcesViews.data.join(', ')}`;
    this.appendData(sourcesViewsData, 'Sources views', 'Sources views');

    const sourcesUsersData = `LABEL:, ${csvDataStorage.sourcesUsers.labels.join(', ')}\n` + `VIEWS:, ${csvDataStorage.sourcesUsers.data.join(', ')}`;
    this.appendData(sourcesUsersData, 'Sources users', 'Sources users');
  };

  appendData = (data: any, name: string, separator = '') => {
    // coma separate, newline each row
    // dashes separator for each result
    this.eachResult.push({ name, data });
    this.result += `${(separator ? `${separator}\n` : '') + data}\n`;
  };

  downloadCSV = () => {
    // write whole result in file
    // create several files for each result
    // zip them all
    const zip = new JSZip();
    zip.file('all_analytics.csv', this.result);
    this.eachResult.forEach((result: { name: string; data: any }) => zip.file(`${result.name}.csv`, result.data));
    zip.generateAsync({ type: 'blob' }).then((content) => {
      // see FileSaver.js
      link.download(file.blobToUrl(content), `${this.tourName}_${this.startDate}-${this.endDate}.zip`);
    });
    return { result: this.result, eachResult: this.eachResult };
  };

  printCSV = () => {
    // console.log('CSV, ', this.result);
  };
}
