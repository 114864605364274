/**
 * Prevent the zooming functionality on mobile devices.
 *
 * @param {Window | undefined} window - The global window on which to prevent zooming
 *
 * <meta name="viewport" content="width=device-width, user-scalable=no" /> .. this tag is not enough since browsers sometimes ignore it
 */
export default function preventZoomInOnMobiles(window: Window): void {
  if (!window) return;

  const preventDefault = (e: Event) => {
    e.preventDefault();
  };

  window.addEventListener('gesturestart', preventDefault, { passive: false });
  window.addEventListener('gesturechange', preventDefault, { passive: false });
  window.addEventListener('gestureend', preventDefault, { passive: false });
}
