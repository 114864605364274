import { Theme } from '@mui/material/styles';

export default function ListItemButton({ palette }: Theme) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover, &.active': {
            color: palette.common.white,
            opacity: 1,
            backgroundColor: 'rgba(256,256,256, .2)',
          },
        },
      },
    },
  };
}
