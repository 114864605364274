import { alpha, Theme } from '@mui/material';

export default function Paper({ palette }: Theme) {
  return {
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          background: alpha(palette.common.black, 0.5),
          backdropFilter: 'blur(15px)',
        },
      },
    },
  };
}
