// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import md5 from 'crypto-js/md5';

const getDeviceFingerprint = () => {
  // Text with lowercase/uppercase/punctuation symbols
  const txt = 'BrowserLeaks,com <canvas> 1.0';
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d'); // 2d, webgl, webgl2, bitmaprenderer
  ctx.textBaseline = 'top';
  // The most common type
  ctx.font = "14px 'Arial'";
  ctx.textBaseline = 'alphabetic';
  ctx.fillStyle = '#f60';
  ctx.fillRect(125, 1, 62, 20);
  // Some tricks for color mixing to increase the difference in rendering
  ctx.fillStyle = '#069';
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
  ctx.fillText(txt, 4, 17);
  const dataString = canvas.toDataURL();
  return md5(dataString).toString();
};

export default getDeviceFingerprint;
