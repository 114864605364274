import { TinyColor } from '@ctrl/tinycolor';
import { ColorInput } from '@ctrl/tinycolor/dist';

const lighter = (color: ColorInput): string => {
  return new TinyColor(color).lighten(30).toString();
};

const light = (color: ColorInput): string => {
  return new TinyColor(color).lighten(15).toString();
};

const dark = (color: ColorInput): string => {
  return new TinyColor(color).darken(15).toString();
};

const darken = (color: ColorInput): string => {
  return new TinyColor(color).darken(30).toString();
};

const color = {
  lighter,
  light,
  dark,
  darken,
};
export default color;
