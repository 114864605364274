const setProperty = (name = '', value = '') => {
  document.documentElement.style.setProperty(name, value);
};

const getProperty = (name = '') => document.documentElement.style.getPropertyValue(name);

const COLOR_PRIMARY = '--color-primary';
const COLOR_SECONDARY = '--color-secondary';

/*
  #TODO: Add types for all Keys
  type Keys = COLOR_PRIMARY | COLOR_SECONDARY;
*/

const css = {
  setProperty,
  getProperty,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
};
export default css;
