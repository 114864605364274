import { createContext, useContext, useState, useEffect } from 'react';
import { allowUsesCookie } from '@/components/common/CookieConsent';

// Create a context
export const CookieAcceptanceContext = createContext(null);

// Create a provider component
export function CookieAcceptanceProvider({ children }) {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);

  // Check for cookie acceptance on mount
  useEffect(() => {
    setHasAcceptedCookies(allowUsesCookie());
  }, []);

  const updateCookieAcceptance = () => {
    // Update state when the function is called.
    setHasAcceptedCookies(allowUsesCookie());
  };

  return <CookieAcceptanceContext.Provider value={{ hasAcceptedCookies, updateCookieAcceptance }}>{children}</CookieAcceptanceContext.Provider>;
}

// Create a hook to use the context
export function useCookieAcceptance() {
  return useContext(CookieAcceptanceContext);
}
