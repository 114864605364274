import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import breakpoints from './breakpoints';
import typography from './typography';
import componentsOverride from './overrides';
import palette from './palette';

export enum Mode {
  Light = 'light',
  Dark = 'dark',
}

type TThemeConfigProps = {
  children: ReactNode;
};

export default function ThemeConfig({ children }: TThemeConfigProps) {
  const themeDirection = 'ltr';

  // Hardcoded for now. Can be added later
  const isDarkMode = false;

  const mode = useMemo(() => (isDarkMode ? Mode.Dark : Mode.Light), [isDarkMode]);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isDarkMode ? { ...palette.dark, mode } : { ...palette.light, mode },
      breakpoints,
      typography,
      direction: themeDirection,
    }),
    [mode, isDarkMode],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
