const blobToUrl = (blob: Blob) => window.URL.createObjectURL(new Blob([blob]));

const existFile = (url: string): Promise<boolean> =>
  new Promise((resolve) => {
    fetch(url, { method: 'HEAD' })
      .then((response) => resolve(!!response.ok))
      .catch(() => resolve(false));
  });

const file = {
  blobToUrl,
  existFile,
};

export default file;
