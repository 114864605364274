import { Theme } from '@mui/material/styles';

export default function Chip({ palette }: Theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          height: '22px',
          '&>span': {
            padding: '0 8px',
          },
        },
        colorSuccess: {
          background: palette.success.light,
          color: palette.success.main,
        },
        colorError: {
          background: palette.error.light,
          color: palette.error.main,
        },
      },
    },
  };
}
