import { Theme } from '@mui/material/styles';

export default function TextField(theme: Theme) {
  const { palette } = theme;

  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: palette.common.white,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: palette.primary.main,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: palette.common.white,
            },
            '&:hover fieldset': {
              borderColor: palette.primary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: palette.primary.main,
            },
          },
        },
      },
    },
  };
}
