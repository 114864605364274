const download = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => reader.result;
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const link = {
  download,
  blobToBase64,
  openInNewTab,
};

export default link;
