import { Theme } from '@mui/material/styles';

export default function ButtonGroup({ palette }: Theme) {
  return {
    MuiButtonGroup: {
      styleOverrides: {
        text: {
          borderRadius: 0,

          borderBottom: '2px solid white !important',
          '&>.MuiButton-root': {
            borderRadius: 0,
            paddingRight: '20px',
            paddingLeft: '20px',
            '&.active': {
              borderBottom: `2px solid ${palette.primary.main} !important`,
            },
            borderColor: `transparent !important`,
          },
        },
      },
    },
  };
}
