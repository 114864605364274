import { Theme } from '@mui/material/styles';

export default function Tab({ palette }: Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          color: palette.grey[400],
          fontSize: '16px',
        },
        selected: {
          color: palette.common.white,
        },
      },
    },
  };
}
