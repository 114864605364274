import { alpha } from '@mui/material/styles';
import { muiColor } from '../utils';

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

interface TreedisColors {
  blue: {
    main: string;
    lighter: string;
    lightest: string;
  };
  cyan: {
    main: string;
  };
  pink: {
    main: string;
  };
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
    darker: string;
  }

  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    treedis: TreedisColors;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

const PRIMARY = {
  light: muiColor.light('#2055ff'),
  main: '#2055ff',
  dark: muiColor.dark('#2055ff'),
};
const SECONDARY = {
  light: '#fea1f6',
  main: '#FD7BF2',
  dark: '#fc23e9',
};
const INFO = {
  light: '#F2F7FF',
  main: '#2D9CDB',
  dark: muiColor.dark('#2D9CDB'),
};
const SUCCESS = {
  light: '#E3FFD6',
  main: '#27AE60',
  dark: muiColor.dark('#27AE60'),
};
const WARNING = {
  light: '#FFF4CD',
  main: '#FAAD39',
  dark: muiColor.dark('#FAAD39'),
};
const ERROR = {
  light: '#FFF1EF',
  main: '#F34848',
  dark: muiColor.dark('#F34848'),
};

const GREY = {
  0: '#FFFFFF',
  100: '#F7F7F7',
  200: '#F2F2F2',
  300: '#EBEBEB',
  400: '#BDBDBD',
  500: '#828282',
  600: '#6B6B6B',
  700: '#4F4F4F',
  800: '#333333',
  900: '#000000',
  500_8: alpha('#828282', 0.08),
  500_12: alpha('#828282', 0.12),
  500_16: alpha('#828282', 0.16),
  500_24: alpha('#828282', 0.24),
  500_32: alpha('#828282', 0.32),
  500_48: alpha('#828282', 0.48),
  500_56: alpha('#828282', 0.56),
  500_80: alpha('#828282', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: '#fff' },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  treedis: {
    blue: {
      main: '#2055FF',
      lighter: alpha('#2055FF', 0.8),
      lightest: alpha('#2055FF', 0.1),
    },
    cyan: {
      main: '#39F8EA',
    },
    pink: {
      main: '#FD7BF2',
    },
  },
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: GREY[300], neutral: GREY[200], darker: GREY[400] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], darker: GREY[800] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
