import { DictionaryOptions } from './types';

const groupBy = <T>(items: T[], group: string) =>
  items.reduce((acc, item) => {
    // TODO: fix Assigned expression type any[] is not assignable to type T[T[string]]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc[item[group]] = [...(acc[item[group]] || []), item];
    return acc;
  }, {});

const toDictionary = <T>(list: T[], userDefinedOptions: DictionaryOptions = {}) => {
  const defaultOptions = {
    indexKey: 'name',
    valueKey: 'value',
  };

  if (!Array.isArray(list)) {
    throw new Error('Cannot convert a non-array value to dictionary.');
  }

  const { indexKey, valueKey, transformValues } = {
    ...defaultOptions,
    ...userDefinedOptions,
  };
  return list.reduce((dictionary, item) => {
    if (!item || !(item[indexKey] || item[indexKey] === 0)) {
      return dictionary;
    }
    return {
      ...dictionary,
      [item[indexKey]]: transformValues ? transformValues(item) : item[valueKey],
    };
  }, {});
};

const getMinItemByField = (data: never[], field: string) => data.reduce((min, p) => (p[field] < min[field] ? p[field] : min), data[0] || null);

const array = {
  groupBy,
  toDictionary,
  getMinItemByField,
};

export default array;
