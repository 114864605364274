import { Theme } from '@mui/material/styles';

export default function Checkbox({ palette }: Theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          color: palette.common.black,
          '&.Mui-disabled': {
            color: palette.grey[500],
          },
          '& .MuiTouchRipple-child': {
            borderRadius: '4px',
          },
          '& .MuiTouchRipple-rippleVisible': {
            borderRadius: '4px',
          },
        },
      },
    },
  };
}
