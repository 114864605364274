import { register } from 'fetch-intercept';

setTimeout(function () {
  register({
    request(url, _config) {
      const config = _config || {};
      const myHeaders = new Headers();

      if (config?.ignoreInterceptor) {
        return [url, config];
      }

      if (!config.headers || !config.headers['Content-Type']) {
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');
      }

      let finalUrl = null;
      if (url instanceof Request) {
        finalUrl = url.url;
      } else if (url.indexOf('http') === -1) {
        const domain = process.env.REACT_APP_DOMAIN || 'http://localhost:5030';
        finalUrl = `${domain}/${url}`;
      } else {
        finalUrl = url;
      }
      config.headers = myHeaders;
      return [finalUrl, config];
    },

    requestError(error) {
      // Called when an error occurred during another 'request' interceptor call
      // tslint:disable-next-line:no-console
      return Promise.reject(error);
    },

    response(response) {
      // Modify the response object
      // tslint:disable-next-line:no-console
      if (!response.ok) {
        // we need to check on the ok property in order to know if we have forbidden or OK status
        return response.json().then((res) => Promise.reject(res));
      }
      return response;
    },

    responseError(error) {
      // Handle an fetch error only network error catches here

      return Promise.reject(error);
    },
  });
}, 0);
